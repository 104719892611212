window.onload = function () {
  var body = document.getElementsByTagName('body')[0]; // body要素を取得
  //会員情報入力画面、お客様情報入力画面の場合
  var customer_forms = {
    page_entry: 'entry',
    page_mypage_change : 'entry',
    page_shopping_nonmember : 'nonmember'
  };

  //会員登録
  if (body.id in customer_forms) {
    let formNameCustomer = customer_forms[body.id] + '_customer';

    //console.log('bodyタグに指定されたIDが一致します。ID:', formNameCustomer);

    // 全てのラジオボタン要素を取得;
    //let customerChoices = document.getElementById(formNameCustomer).querySelectorAll('input[type="radio"]');
    // 各ラジオボタンに対してイベントリスナーを設定

    /*

    customerChoices.forEach(function(customerChoice) {
      customerChoice.addEventListener('change', function (event) {
        formNameCompany = customer_forms[body.id] + '_company_name';
        formNameCompanyKana = customer_forms[body.id] + '_company_name_kana';

        if (event.target.checked) {
          // 選択されたラジオボタンの値を取得
          var selectedValue = event.target.value;
          // 選択されたラジオボタンの値に基づく処理を実行
          console.log('選択された値:', selectedValue);
          if (selectedValue == '1') { //個人の場合
            // 会社名を入力不可能にする
            document.getElementById(formNameCompany).disabled = true;
            document.getElementById(formNameCompanyKana).disabled = true;
          } else if (selectedValue == '2') { //法人の場合
            // 会社名を入力可能にする
            document.getElementById(formNameCompany).disabled = false;
            document.getElementById(formNameCompanyKana).disabled = false;
          }


        }
      });
    });
    */
  }

  //商品詳細ページの場合
  /*
  if (body.id in page_product_detail) {




  }
  */

}
